import { EnumOption } from '@shared/enums/enum-option';
import { Color } from '@shared/enums/ui';
import { NullEnumOption } from '@shared/models/null-enum-option';

export enum EventType {
    ONLINE = 'online',
    OFFLINE = 'offline',
    HYBRID = 'hybrid',
}

export namespace EventType {
    export function getValues(): EnumOption<EventType>[] {
        return [
            { id: EventType.ONLINE, text: 'Virtual', color: Color.Turquoise },
            { id: EventType.OFFLINE, text: 'Offline', color: Color.Light },
            { id: EventType.HYBRID, text: 'Hybrid', color: Color.Default },
        ];
    }

    export function find(id: EventType): EnumOption<EventType> {
        const search = EventType.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
