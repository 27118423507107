import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormComponent } from '@ubud/form';
import { CreateEventDto } from '@ebursa/event/dto/create-event.dto';
import { of } from 'rxjs';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AgmMap, GoogleMapsAPIWrapper, MapsAPILoader, MouseEvent } from '@agm/core';
import { EventType } from '@ebursa/event/enums/event-type';
import { EnumOption } from '@shared/enums/enum-option';
import { Validators } from '@angular/forms';
import { Subscriber } from '@ubud/sate';
import { tap } from 'rxjs/operators';
import { EventStatus } from '@ebursa/event/enums/event-status';

declare var google: any;

@Component({
    selector: 'ebursa-event-form',
    templateUrl: './event.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventForm extends FormComponent<CreateEventDto> implements OnInit, OnChanges {
    @ViewChild('placesRef') placesRef: GooglePlaceDirective;
    @ViewChild(AgmMap) public map: AgmMap;

    @Output() public changeCity: EventEmitter<number> = new EventEmitter<number>();
    @Input() public status: EventStatus;

    public dateFormat: string = 'dd-MM-yyyy';
    public timeFormat: string = 'hh:mm a';
    public geocoder: any;
    public disableFormUpdate: boolean;

    // google maps zoom level
    public zoom: number = 10;

    // initial center position for the map
    public markers: Marker = {
        lat: -6.2192977490342285,
        lng: 106.83632950814388,
        draggable: true,
    };

    public constructor(
        public mapsApiLoader: MapsAPILoader,
        private zone: NgZone,
        private wrapper: GoogleMapsAPIWrapper,
        private subscriber: Subscriber,
    ) {
        super();
        this.mapsApiLoader = mapsApiLoader;
        this.zone = zone;
        this.wrapper = wrapper;
        this.mapsApiLoader.load().then(() => {
            this.geocoder = new google.maps.Geocoder();
        });
    }

    public get eventType(): EnumOption<EventType>[] {
        return EventType.getValues();
    }

    public get checkEventType(): boolean {
        const eventType: string = this.form.formGroup.get('type').value;
        return eventType === 'online';
    }

    public handleLatLngChange(data: any): void {
        // console.log(data);
    }

    public handleEventTypeChange(value: any): void {
        if (value === 'online') {
            this.form.formGroup.get('city').setValidators([]);
            this.form.formGroup.get('onlineDescription').setValidators([Validators.required]);
            this.form.formGroup.get('buildingName').setValidators([]);
            this.form.formGroup.get('address').setValidators([]);
            this.form.formGroup.get('location').setValidators([]);
            this.form.formGroup.get('latitude').setValidators([]);
            this.form.formGroup.get('longitude').setValidators([]);

            this.form.formGroup.get('city').updateValueAndValidity();
            this.form.formGroup.get('onlineDescription').updateValueAndValidity();
            this.form.formGroup.get('buildingName').updateValueAndValidity();
            this.form.formGroup.get('address').updateValueAndValidity();
            this.form.formGroup.get('location').updateValueAndValidity();
            this.form.formGroup.get('latitude').updateValueAndValidity();
            this.form.formGroup.get('longitude').updateValueAndValidity();

            this.form.formGroup.updateValueAndValidity();
        } else if (value === 'offline') {
            this.form.formGroup.get('city').setValidators([Validators.required]);
            this.form.formGroup.get('onlineDescription').setValidators([]);
            this.form.formGroup.get('buildingName').setValidators([Validators.required]);
            this.form.formGroup.get('address').setValidators([Validators.required]);
            this.form.formGroup.get('location').setValidators([Validators.required]);
            this.form.formGroup.get('latitude').setValidators([Validators.required]);
            this.form.formGroup.get('longitude').setValidators([Validators.required]);

            this.form.formGroup.get('city').updateValueAndValidity();
            this.form.formGroup.get('onlineDescription').updateValueAndValidity();
            this.form.formGroup.get('buildingName').updateValueAndValidity();
            this.form.formGroup.get('address').updateValueAndValidity();
            this.form.formGroup.get('location').updateValueAndValidity();
            this.form.formGroup.get('latitude').updateValueAndValidity();
            this.form.formGroup.get('longitude').updateValueAndValidity();

            this.form.formGroup.updateValueAndValidity();
        }
    }

    public handleFilterCity(event: any): void {
        this.changeCity.emit(event);
    }

    public clickedMarker(label: string) {}

    public mapClicked($event: MouseEvent) {
        this.setLocation($event);
    }

    public markerDragEnd(m: Marker, $event: MouseEvent) {
        this.setLocation($event);
    }

    public findLocationByCoordinates() {
        this.mapsApiLoader.load().then(() => {
            this.geocoder.geocode(
                {
                    location: {
                        lat: this.markers.lat,
                        lng: this.markers.lng,
                    },
                },
                (results: any, status: any) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            this.form.formGroup.patchValue({
                                location: results[0].formatted_address,
                            });
                        }
                    }
                },
            );
        });
    }

    public handleAddressChange(address: Address) {
        this.form.formGroup.patchValue({
            latitude: address.geometry.location.lat(),
            longitude: address.geometry.location.lng(),
        });

        this.markers = {
            lat: Number(address.geometry.location.lat()),
            lng: Number(address.geometry.location.lng()),
            draggable: true,
        };
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(
            this,
            of(this.form.formGroup.getRawValue()).pipe(
                tap((value: any) => {
                    /*console.log(value);*/
                }),
            ),
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['status'] && this.status != null) {
            this.disableFormUpdate = this.status === EventStatus.GOING;
        }
    }

    private setLocation($event: MouseEvent) {
        this.markers = {
            lat: $event.coords.lat,
            lng: $event.coords.lng,
            draggable: true,
        };

        this.form.formGroup.patchValue({
            latitude: $event.coords.lat,
            longitude: $event.coords.lng,
        });

        this.findLocationByCoordinates();
    }
}

interface Marker {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
}
