import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
export class ApplicantVacancy extends UUIDModel<ApplicantVacancy> {
    public applicantEventId: string;
    public companyVacancyEventId: string;
    public reason: string;
    public status: ApplicantVacancyStatus;
    public selectedApplicant?: boolean;

    @Type(forwardRef(() => Date) as any)
    public acceptedAt: Date;
    @Type(forwardRef(() => Date) as any)
    public canceledAt: Date;
    @Type(forwardRef(() => Date) as any)
    public rejectedAt: Date;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    @Type(forwardRef(() => ApplicantEvent) as any)
    public applicantEvent: ApplicantEvent;

    @Type(forwardRef(() => CompanyVacancyEvent) as any)
    public companyVacancyEvent: CompanyVacancyEvent;
}
