import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { User } from '@ebursa/auth/models/user';
import { RoleType } from '@ebursa/auth/enums/role-type';

@Component({
    selector: 'ebursa-applicant-vacancy-list',
    templateUrl: './applicant-vacancy.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicantVacancyList extends DataGridComponent<ApplicantVacancy> {
    @Input() roleUser!: User;
    public applicantVacancyStatus = ApplicantVacancyStatus;

    // Track the state of "Select All" checkbox
    public selectAllChecked = false;

    // Toggle individual selection
    public toggleSelection(item: any): void {
        item.selectedApplicant = !item.selectedApplicant;
        this.updateSelectAllChecked();
    }

    // Toggle "Select All" functionality
    public toggleSelectAll(event: any): void {
        this.selectAllChecked = (event.target as HTMLInputElement).checked;
        this.collection.data.data.forEach(item => (item.selectedApplicant = this.selectAllChecked));
    }

    // Check if all items are selected and update selectAllChecked
    private updateSelectAllChecked(): void {
        this.selectAllChecked = this.collection.data.data.every(item => item.selectedApplicant);
    }

    public get role(): typeof RoleType {
        return RoleType;
    }
}
