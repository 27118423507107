import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'ebursa-company-management-history',
    templateUrl: './company-management-history.component.html',
    styleUrls: ['./company-management-history.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyManagementHistoryComponent {
    public queries$: Observable<any>;
    public statuses = [{ id: 1, text: 'Active' }, { id: 2, text: 'Inactive' }, { id: 3, text: 'Pending' }];

    private queriesSubject = new BehaviorSubject({
        keyword: '',
        status: null,
    });

    public collection = {
        data: [
            {
                id: 1,
                name: 'PT ABC Teknologi',
                email: 'contact@abcteknologi.com',
                logoUri: '/assets/logos/abc.png',
                village: {
                    city: {
                        name: 'Jakarta',
                    },
                },
                wlkpCode: 'WLKP001',
                ownerName: 'John Doe',
                ownerEmail: 'john.doe@abcteknologi.com',
                ownerPhone: '081234567890',
                website: 'https://www.abcteknologi.com',
            },
            {
                id: 2,
                name: 'PT Digital Inovasi',
                email: 'info@digitalinovasi.co.id',
                logoUri: '/assets/logos/digital.png',
                village: {
                    city: {
                        name: 'Bandung',
                    },
                },
                wlkpCode: 'WLKP002',
                ownerName: 'Jane Smith',
                ownerEmail: 'jane.smith@digitalinovasi.co.id',
                ownerPhone: '081987654321',
                website: 'https://www.digitalinovasi.co.id',
            },
            {
                id: 3,
                name: 'CV Kreatif Solusi',
                email: 'support@kreatifsolusi.com',
                logoUri: '/assets/logos/kreatif.png',
                village: {
                    city: {
                        name: 'Surabaya',
                    },
                },
                wlkpCode: 'WLKP003',
                ownerName: 'Michael Johnson',
                ownerEmail: 'michael.johnson@kreatifsolusi.com',
                ownerPhone: '082134567890',
                website: 'https://www.kreatifsolusi.com',
            },
            {
                id: 4,
                name: 'PT Mitra Solusi',
                email: 'mitra@solusi.com',
                logoUri: '/assets/logos/mitra.png',
                village: {
                    city: {
                        name: 'Yogyakarta',
                    },
                },
                wlkpCode: 'WLKP004',
                ownerName: 'Sarah Williams',
                ownerEmail: 'sarah.williams@mitrasolusi.com',
                ownerPhone: '081112233445',
                website: 'https://www.mitrasolusi.com',
            },
        ],
        limit: 10,
        skip: 0,
    };

    constructor() {
        // Set initial queries$ observable data
        this.queries$ = this.queriesSubject.asObservable();
    }

    // Function to handle filter changes (keyword or status)
    public handleFilter(field: string, value: any): void {
        const currentQueries = this.queriesSubject.value;
        const updatedQueries = { ...currentQueries, [field]: value };
        this.queriesSubject.next(updatedQueries);
    }

    // Function to reset filter (specific fields)
    public resetFilter(fields: string[]): void {
        const currentQueries = this.queriesSubject.value;
        const updatedQueries = { ...currentQueries };

        fields.forEach(field => {
            updatedQueries[field] = null; // Reset the specified fields to null
        });

        this.queriesSubject.next(updatedQueries);
    }
}
