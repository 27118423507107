import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';

@Component({
    selector: 'ebursa-company-applicant-vacancy-list',
    templateUrl: './company-applicant-vacancy.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyApplicantVacancyList extends DataGridComponent<ApplicantVacancy> {
    @Output() public showDetail: EventEmitter<ApplicantVacancy> = new EventEmitter();

    // Track the state of "Select All" checkbox
    public selectAllChecked = false;

    // Toggle individual selection
    public toggleSelection(item: any): void {
        item.selectedApplicant = !item.selectedApplicant;
        this.updateSelectAllChecked();
    }

    // Toggle "Select All" functionality
    public toggleSelectAll(event: any): void {
        this.selectAllChecked = (event.target as HTMLInputElement).checked;
        this.collection.data.data.forEach(item => (item.selectedApplicant = this.selectAllChecked));
    }

    // Check if all items are selected and update selectAllChecked
    private updateSelectAllChecked(): void {
        this.selectAllChecked = this.collection.data.data.every(item => item.selectedApplicant);
    }
}
