import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { mergeMap, filter, tap, first, map, switchMap } from 'rxjs/operators';
import { ApplicantVacancyService } from '@ebursa/event/services/applicant-vacancy.service';
import { ApplicantVacancyRepository } from '@ebursa/event/repositories/applicant-vacancy.repository';
import { Observable, combineLatest } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { EnumOption } from '@shared/enums/enum-option';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';
import { GridData } from '@shared/types/grid';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { User } from '@ebursa/auth/models/user';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { Form, FormValue } from '@ubud/form';
import { RejectApplicantVacancyDto } from '@ebursa/event/dto/reject-applicant-vacancy.dto';
import { InviteApplicantVacancyDto } from '@ebursa/event/dto/invite-applicant-vacancy.dto';
import { DatePipe } from '@angular/common';
import { RejectApplicantVacancyFormFactory } from '@ebursa/event/factories/reject-applicant-vacancy-form.factory';
import { InviteApplicantVacancyFormFactory } from '@ebursa/event/factories/invite-applicant-vacancy-form.factory';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Signature } from '@naker/naco';
import { environment } from '@ebursa/web/src/environments/environment';
import { CityService } from '@ebursa/addressing/services/city.service';
import { CityRepository } from '@ebursa/addressing/repositories/city.repository';
import { EducationService } from '@ebursa/education/services/education.service';
import { EducationRepository } from '@ebursa/education/repositories/education.repository';
import { CompanyService } from '@ebursa/company/services/company.service';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { CompanyEvent } from '@ebursa/company/models/company-event';
import { CompanyRepository } from '@ebursa/company/repositories/company.repository';
import { CompanyPositions } from '@ebursa/company/models/company';

interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
    type: string;
    gender: string;
    region: string[];
    education: string[];
    job_title: string[];
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 10;
    public page = 1;
    public status = null;
    public type = null;
    public gender = null;
    public region = [];
    public education = [];
    public job_title = [];
}

@Component({
    selector: 'ebursa-applicant-vacancies-event-grid-container',
    templateUrl: './applicant-vacancies-event-grid.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe, RejectApplicantVacancyFormFactory, InviteApplicantVacancyFormFactory],
})
export class ApplicantVacanciesEventGridContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public rejectForm: Form;
    public inviteForm: Form;
    public selectedRejectApplicantVacancy: string;
    public selectedInviteApplicantVacancy: string;
    public applicantVacancyStatus = ApplicantVacancyStatus;

    public genders = [{ id: '10', text: 'Pria' }, { id: '20', text: 'Wanita' }];

    public constructor(
        public route: ActivatedRoute,
        public router: Router,
        private subscriber: Subscriber,
        private authRepository: AuthRepository,
        private service: ApplicantVacancyService,
        private repository: ApplicantVacancyRepository,
        private rejectApplicantVacancyFormFactory: RejectApplicantVacancyFormFactory,
        private inviteInterviewApplicantVacancyFormFactory: InviteApplicantVacancyFormFactory,
        private notificationService: NotificationService,
        private datePipe: DatePipe,
        private cityService: CityService,
        private cityRepository: CityRepository,
        private educationService: EducationService,
        private educationRepository: EducationRepository,
        private companyService: CompanyService,
        private companyRepostiory: CompanyRepository,
        private eventRepostiory: EventRepository,
    ) {
        super(router, route, new InitialQueryParams());

        this.rejectForm = this.rejectApplicantVacancyFormFactory.create();
        this.inviteForm = this.inviteInterviewApplicantVacancyFormFactory.create();
    }

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get cities$(): Observable<any> {
        return this.cityRepository.getCities$().pipe(
            map((res: any) => {
                return res.map((city: any) => ({
                    id: city.name,
                    text: city.name,
                }));
            }),
        );
    }

    public get loadingCities$(): Observable<boolean> {
        return this.cityRepository.isLoading$();
    }

    private findSubtitleById<T extends { id: string; text: string }>(items: T[], queryIds: string | string[]): string | null {
        if (!items || items.length === 0) return null;

        // Jika queryIds adalah array, cari setiap ID dalam array dan gabungkan teksnya
        if (Array.isArray(queryIds)) {
            const foundItems = items.filter(item => queryIds.includes(item.id));
            return foundItems.length > 0 ? foundItems.map(item => item.text).join(', ') : null;
        }

        // Jika queryIds adalah string tunggal
        const foundItem = items.find(item => item.id === queryIds);
        return foundItem ? foundItem.text : null;
    }

    public get citySubtitle$(): Observable<string | null> {
        return this.cities$.pipe(map((cities: any[]) => this.findSubtitleById(cities, this.queries.region)));
    }

    public get genderSubtitle$(): string | null {
        return this.findSubtitleById(this.genders, this.queries.gender);
    }

    public get positionSubtitle$(): Observable<string | null> {
        return this.positions$.pipe(map((positions: any[]) => this.findSubtitleById(positions, this.queries.job_title)));
    }

    public get educationSubtitle$(): Observable<string | null> {
        return this.educations$.pipe(map((educations: any[]) => this.findSubtitleById(educations, this.queries.education)));
    }

    public get loadingEducation$(): Observable<boolean> {
        return this.educationRepository.isLoading$();
    }

    public get educations$(): Observable<any> {
        return this.educationRepository.getEductions$().pipe(
            filter(education => !!education),
            map((res: any) => {
                return res.map(education => ({
                    id: education.name,
                    text: education.name,
                }));
            }),
        );
    }

    public get positions$(): Observable<Collection<CompanyPositions>> {
        return this.companyRepostiory.selectCompanyPositions$().pipe(
            filter(positions => !!positions),
            map((position: any) => {
                return position.map(item => ({
                    id: item.jobTitle,
                    text: item.jobTitle,
                }));
            }),
        );
    }

    public get getCompanyRegisteredForEvent$(): Observable<CompanyEvent> {
        return this.eventRepostiory.getCompanyRegisteredForEvent$();
    }

    public get snippedRoleUser$(): Observable<User> {
        return combineLatest(this.user$, this.route.queryParams).pipe(
            first(),
            map(([user, { currentRole }]) => {
                if (!!currentRole) {
                    return new User({
                        ...user,
                        roles: user.roles.filter(item => item.name === currentRole),
                    });
                } else {
                    return new User({ ...user });
                }
            }),
        );
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get applicantVacancies$(): Observable<GridData<ApplicantVacancy>> {
        return this.repository.getApplicantVacancies$().pipe(
            filter(applicantVacancies => !!applicantVacancies),
            mapToGridData(ApplicantVacancy),
        );
    }

    public showApplicantVacancyDialog(applicantVacancy: ApplicantVacancy): void {
        this.service.setApplicantVacancyDialog(applicantVacancy);
    }

    public closeApplicantVacancyDialog(): void {
        this.service.setApplicantVacancyDialog(null);
    }

    public getCities(event) {
        return this.cityService.getCities(event).subscribe();
    }

    public getEducations() {
        return this.educationService.getEducations().subscribe();
    }

    public getCompanyPositions(eventId: string) {
        return this.companyService.getCompanyPositions(eventId).subscribe();
    }

    public get applicantVacancyDialog$(): Observable<ApplicantVacancy> {
        return this.repository.getApplicantVacancyDialog$();
    }

    public get applicantVacancyManagementLoading$(): Observable<boolean> {
        return this.repository.isManagementLoading$();
    }

    public get statuses(): EnumOption<ApplicantVacancyStatus>[] {
        return ApplicantVacancyStatus.getValues();
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public formatArrayQueryParam(paramArray: string[], paramName: string): string | null {
        return paramArray && paramArray.length ? paramArray.map(value => `${paramName}[]=${value}`).join('&') : null;
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());
        this.subscriber.subscribe(
            this,
            combineLatest(this.snippedRoleUser$, this.queries$).pipe(
                filter(([user]) => !!user),
                mergeMap(([user, queries]: [User, QueryParams]) => {
                    // Format array query parameters
                    this.formatArrayQueryParam(queries.job_title, 'job_title');
                    this.formatArrayQueryParam(queries.education, 'education');
                    this.formatArrayQueryParam(queries.region, 'region');

                    queries.job_title && queries.job_title.length ? queries.job_title.map(title => `job_title[]=${title}`).join('&') : null;
                    queries.education && queries.education.length ? queries.education.map(title => `education[]=${title}`).join('&') : null;

                    const params = {
                        ...queries,
                        status: queries.status ? [queries.status] : this.statuses.map(item => item.id),
                        limit: 10,
                    };

                    if (user.hasRole(this.role.COMPANY)) {
                        return this.service.getApplicantVacanciesByCompanyEvent(this.route.snapshot.params.companyEvent, params);
                    } else {
                        return this.service.getApplicantVacanciesByEvent(this.route.snapshot.params.event, params);
                    }
                }),
            ),
        );

        this.getEducations();

        this.getCompanyPositions(this.route.snapshot.params.event);
    }

    ngOnChanges(): void {
        this.bindFilter();
    }

    public showRejectApplicantVacancyDialog(applicantVacancy: ApplicantVacancy): void {
        this.selectedRejectApplicantVacancy = applicantVacancy.id.toString();
        this.service.setRejectApplicantVacancyDialog(applicantVacancy);
    }

    public closeRejectApplicantVacancyDialog(): void {
        this.service.setRejectApplicantVacancyDialog(null);
    }

    public get rejectApplicantVacancyDialog$(): Observable<ApplicantVacancy | null> {
        return this.repository.getRejectApplicantVacancyDialog$();
    }

    public acceptApplicantVacancy(applicantVacancy: string): void {
        this.subscriber.subscribe(
            this,
            this.service.acceptApplicantVacancy(applicantVacancy).pipe(
                tap(() => this.ngOnInit()),
                tap(() => {
                    this.notificationService.show({
                        content: 'Berhasil menerima pencari kerja',
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
                tap(() => this.closeApplicantVacancyDialog()),
            ),
        );
    }

    public rejectApplicantVacancy(payload: FormValue<RejectApplicantVacancyDto>): void {
        this.subscriber.subscribe(
            this,
            this.service.rejectApplicantVacancy(this.selectedRejectApplicantVacancy, payload.data).pipe(
                tap(() => this.ngOnInit()),
                tap(() => this.rejectForm.formGroup.reset()),
                tap(() => {
                    this.notificationService.show({
                        content: 'Berhasil menolak pencari kerja',
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
                tap(() => this.service.setRejectApplicantVacancyDialog(null)),
                tap(() => this.service.setApplicantVacancyDialog(null)),
            ),
        );
    }

    private parse(json) {
        Object.keys(json).map(key => {
            const date = new Date(json[key]);
            if (!isNaN(date.getTime())) {
                json[key] = date;
            }
        });

        return json;
    }

    public inviteApplicantVacancy(payload: FormValue<InviteApplicantVacancyDto>): void {
        if ('VALID' === payload.status) {
            Object.assign(payload.data, {
                interviewDate: this.datePipe.transform(payload.data.interviewDate, 'yyyy-MM-dd'),
                interviewTime: this.datePipe.transform(this.parse(payload.data.interviewTime), 'HH:mm:ss'),
            });
            this.subscriber.subscribe(
                this,
                this.service.inviteApplicantVacancy(this.selectedInviteApplicantVacancy, payload.data).pipe(
                    tap(() => this.ngOnInit()),
                    tap(() => this.inviteForm.formGroup.reset()),
                    tap(() => {
                        this.notificationService.show({
                            content: 'Berhasil mengundang wawancara pencari kerja',
                            cssClass: 'button-notification',
                            animation: { type: 'fade', duration: 800 },
                            position: { horizontal: 'right', vertical: 'top' },
                            type: { style: 'success', icon: true },
                            hideAfter: 2000,
                        });
                    }),
                    tap(() => this.closeInviteInterviewDialog()),
                    tap(() => this.closeApplicantVacancyDialog()),
                ),
            );
        }
    }

    public get inviteInterviewApplicantVacancyDialog$(): Observable<ApplicantVacancy | null> {
        return this.repository.getInviteInterviewApplicantVacancyDialog$();
    }

    public openInviteInterviewDialog(applicantVacancy: ApplicantVacancy): void {
        this.selectedInviteApplicantVacancy = applicantVacancy.id.toString();
        this.service.setInviteInterviewApplicantVacancyDialog(applicantVacancy);
    }

    public closeInviteInterviewDialog(): void {
        this.service.setInviteInterviewApplicantVacancyDialog(null);
    }

    public handleDownloadDocument(documentId: string): void {
        this.subscriber.subscribe(
            this,
            this.authRepository.getSignature$().pipe(
                filter(res => !!res),
                tap((signature: Signature) => {
                    window.open(`${environment.endpoint}prohub/documents/${documentId}/download?_token=${signature.token}`);
                }),
            ),
        );
    }

    public handleDownloadCV(event: { userId: string }): void {
        const { userId } = event;
        this.subscriber.subscribe(
            this,
            this.authRepository.getSignature$().pipe(
                filter(res => !!res),
                tap((signature: Signature) => {
                    window.open(`${environment.endpoint}user/${userId}/cv/download?_token=${signature.token}`);
                }),
            ),
        );
    }

    public handleDownloadMultipleCV(): void {
        this.applicantVacancies$.subscribe(applicantVacancies => {
            const { data } = applicantVacancies.data;

            // Use a Set to collect unique user IDs
            const uniqueIds = new Set(
                data.filter(item => item.selectedApplicant === true).map(item => item.applicantEvent.applicant.user.id as string),
            );

            if (uniqueIds.size === 0) {
                return;
            }

            // Join unique IDs to form the query parameter string
            const selectedIds = Array.from(uniqueIds)
                .map(id => `users[]=${id}`)
                .join('&');

            this.subscriber.subscribe(
                this,
                this.authRepository.getSignature$().pipe(
                    filter(res => !!res),
                    tap((signature: Signature) => {
                        window.open(`${environment.endpoint}user/cv/downloadAll?${selectedIds}&_token=${signature.token}`);
                    }),
                ),
            );
        });
    }
}
