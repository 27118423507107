import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Event } from '@ebursa/event/models/event';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { EventType } from '@ebursa/event/enums/event-type';

@Component({
    selector: 'ebursa-event-detail-component',
    templateUrl: './event-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDetailComponent {
    public isConfirmDialogOpen = false;
    public isOpenBarcodeDialog = false;
    @Input() public event: Event;
    @Input() public companyEvent: CompanyEvent;
    @Input() public isCompany: boolean;
    @Input() public isOrganizer: boolean;

    @Output() public organizerClicked: EventEmitter<any> = new EventEmitter<any>();

    public get eventStatus(): typeof EventStatus {
        return EventStatus;
    }

    public get eventType(): typeof EventType {
        return EventType;
    }

    public openConfirmDialog(): void {
        this.isConfirmDialogOpen = true;
    }

    public openBarcodeDialog(): void {
        this.isOpenBarcodeDialog = true;
    }

    public closeConfirmDialog(): void {
        this.isConfirmDialogOpen = false;
    }

    public closeBarcodeDialog(): void {
        this.isOpenBarcodeDialog = false;
    }

    public get showBarcodeButton(): boolean {
        return this.isOrganizer && (this.event.type === EventType.OFFLINE || this.event.type === EventType.HYBRID);
    }
}
