// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The lists of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    endpoint: 'https://ebursa-be.staging.impstudio.id/v1/',
    // endpoint: 'https://jobfair-api.kemnaker.go.id/v1/',
    walaUrl: 'https://wajiblapor.kemnaker.go.id',
    karirhubUrl: 'https://karirhub-dev.kemnaker.go.id',
    newKarirhubUrl: 'https://sandbox.karirhub.kemnaker.go.id',
    naco: {
        clientId: 'b2653bc4-e8eb-46f3-ab64-32f012c3dbf1',
    },
    // mapApiKey: 'AIzaSyBVEMXKR3O1oUuV1Q7LlgeOlwhvLfen4f8',
    mapApiKey: 'AIzaSyCiZAWcjkHjScXcVkXE2lYA9kJRKL03sEI',
};

/*
 * In development mode, to ignore zone related error stack frames such  as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
