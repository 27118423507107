import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EducationService } from '@ebursa/education/services/education.service';
import { EducationClient } from '@ebursa/education/clients/education.client';

const SERVICES: any[] = [EducationService];
const CLIENTS: any[] = [EducationClient];

@NgModule({
    imports: [CommonModule],
    providers: [...SERVICES, ...CLIENTS],
})
export class EbursaEducationModule {}
