import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { mapToData } from '@ebursa/api/transformers/responses.transformer';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EducationClient {
    public constructor(private client: EbursaApiClient) {}

    public getEducations(): Observable<any> {
        return this.client.get('educations').pipe(mapToData());
    }
}
