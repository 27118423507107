import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { EventService } from '@ebursa/event/services/event.service';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { Subscriber } from '@ubud/sate';
import { Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';

@Component({
    selector: 'ebursa-upcoming-event-dialog-component',
    templateUrl: './upcoming-event-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./upcoming-event-dialog.component.scss'],
})
export class UpcomingEventDialogComponent implements OnInit, OnDestroy {
    @Input() public isDialogOpen: boolean;
    @Input() public upcomingEvents: Observable<Event[]>;
    public currentSlide: number;
    public cardsToShow: number;
    public totalCards: number;
    public dots: any[];

    public constructor(private eventService: EventService, private eventRepository: EventRepository, private subscriber: Subscriber) {
        this.currentSlide = 0;
        this.cardsToShow = 3;

        this.dots = [];
    }

    public ngOnInit(): void {
        this.upcomingEvents.subscribe(events => {
            if (events) {
                this.totalCards = events.length;
                const dotsCount = Math.max(this.totalCards - this.cardsToShow + 1, 0); // Pastikan panjang array tidak negatif
                this.dots = Array(dotsCount)
                    .fill(0)
                    .map((x, i) => i);
            }
        });
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    nextSlide() {
        if (this.currentSlide < this.totalCards - this.cardsToShow) {
            this.currentSlide++;
        }
    }

    prevSlide() {
        if (this.currentSlide > 0) {
            this.currentSlide--;
        }
    }

    closeDialog() {
        this.isDialogOpen = false;
    }

    truncateText(text: string, maxLength: number): string {
        if (!text) return '';
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    }
}
