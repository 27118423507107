import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { Company } from '@ebursa/company/models/company';
import { UserStatus } from '@ebursa/user/enums/user-status';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { ActivatedRoute } from '@angular/router';

/**
 * Component for managing and displaying a list of companies in a grid format.
 * It extends the `DataGridComponent` to provide grid functionality for companies.
 *
 * @extends DataGridComponent<Company>
 * @author  Bagas Prasetyadi
 */
@Component({
    selector: 'ebursa-company-manage-list-component',
    templateUrl: './company-manage-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyManageListComponent extends DataGridComponent<Company> {
    @Output() public delete: EventEmitter<string> = new EventEmitter<string>();
    public UserStatus = UserStatus;

    public constructor(private activatedRoute: ActivatedRoute, private redirector: RouterRedirector) {
        super();
    }

    public deleteCompany(event: string): void {
        this.delete.emit(event);
    }

    public handleDetailClicked(id: string): void {
        this.redirector.redirectWithPrev(`../manage/detail/${id}`, this.activatedRoute);
    }
}
