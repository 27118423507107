import { CompanyListItemComponent } from '@ebursa/web/src/modules/company/components/list/company-list-item.component';
import { CompanyDetailDialogComponent } from './company-detail-dialog.component';
import { CompanyPublicItemComponent } from './company-public-item.component';
import { CompanySearchFormComponent } from './company-search-form.component';
import { CompanyPublicDetailCardComponent } from './company-public-detail-card.component';
import { CompanyPublicDetailProfileComponent } from './company-public-detail-profile.component';
import { CompanyGridComponent } from '@ebursa/web/src/modules/company/components/grid/company-grid.component';
import { CompanyProfileForm } from '@ebursa/web/src/modules/company/components/forms/company/company-profile.form';
import { CompanyDetailComponent } from '@ebursa/web/src/modules/company/components/dialog/company-detail.component';
import { CompanyTeamListComponent } from '@ebursa/web/src/modules/company/components/company-team/company-team-list.component';
import { InviteCompanyTeamFormComponent } from '@ebursa/web/src/modules/company/components/company-team/invite-company-team-form.component';
import { UpdateCompanyTeamFormComponent } from '@ebursa/web/src/modules/company/components/company-team/update-company-team-form.component';
import { CompanyManageListComponent } from '@ebursa/web/src/modules/company/components/list/company-manage-list.component';
import { CompanyManagementHistoryComponent } from './company-management-history.component';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    23/01/2020
 */
export const COMPANY_COMPONENTS = [
    CompanyListItemComponent,
    CompanyDetailDialogComponent,
    CompanyPublicItemComponent,
    CompanySearchFormComponent,
    CompanyPublicDetailCardComponent,
    CompanyPublicDetailProfileComponent,
    CompanyGridComponent,
    CompanyProfileForm,
    CompanyDetailComponent,
    CompanyTeamListComponent,
    InviteCompanyTeamFormComponent,
    UpdateCompanyTeamFormComponent,
    CompanyManageListComponent,
    CompanyManagementHistoryComponent,
];
