import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';
import { Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { Subscriber } from '@ubud/sate';
import { EventService } from '@ebursa/event/services/event.service';
import { formatDate } from '@angular/common';

/**
 * Component to display the event calendar.
 * Utilizes `angular-calendar` to show events in a monthly view.
 * @author Bagas Prasetyadi
 */

@Component({
    selector: 'ebursa-event-calendar',
    templateUrl: './event-calendar.component.html',
    styleUrls: ['./event-calendar.component.scss'],
})
export class EventCalendarComponent implements OnChanges {
    @Input() public upcomingEvents: Observable<Event[]>;
    public viewDate: Date = new Date();
    public view: 'month' | 'week' | 'day' = 'month';
    public events: CalendarEvent[] = [];
    public isDialogOpen: boolean = false;
    public months = [
        { id: 0, text: 'Januari' },
        { id: 1, text: 'Februari' },
        { id: 2, text: 'Maret' },
        { id: 3, text: 'April' },
        { id: 4, text: 'Mei' },
        { id: 5, text: 'Juni' },
        { id: 6, text: 'Juli' },
        { id: 7, text: 'Agustus' },
        { id: 8, text: 'September' },
        { id: 9, text: 'Oktober' },
        { id: 10, text: 'November' },
        { id: 11, text: 'Desember' },
    ];
    public years: number[];

    public selectedMonth: number = new Date().getMonth();
    public selectedYear: number = new Date().getFullYear();

    public selectedEventId: string;
    public showMoreDialogOpen: boolean = false;
    public moreEvents: CalendarEvent[] = [];
    public moreEventsDate: Date;
    public visibleEvents: number = 2;

    public constructor(private eventRepository: EventRepository, private subscriber: Subscriber, private eventService: EventService) {
        // Generate years from 2023 to next year dynamically
        const currentYear = new Date().getFullYear();
        this.years = this.generateYears(2023, currentYear + 1);
    }

    public get loading$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public openDialog(event: CalendarEvent): void {
        this.selectedEventId = event.id as string;
        this.isDialogOpen = true;
    }

    public closeDialog(): void {
        this.isDialogOpen = false;
    }

    public dayClicked(day: CalendarMonthViewDay): void {
        if (day.events.length) {
            this.isDialogOpen = true;
        }
    }

    public getRandomColor(): string {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    public addEvent(): void {
        this.events.push({
            start: new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 20),
            end: new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 20, 10),
            title: 'New Event',
        });
    }

    public setView(view: 'month' | 'week' | 'day'): void {
        this.view = view;
    }

    public onMonthChange(month: any): void {
        this.selectedMonth = month.id;
        this.updateCalendar();
    }

    public onYearChange(year: number): void {
        this.selectedYear = year;
        this.updateCalendar();
    }

    public updateCalendar(): void {
        if (this.selectedYear != null && this.selectedMonth != null) {
            this.viewDate = new Date(this.selectedYear, this.selectedMonth, 1);

            this.eventService
                .getEventCalendars({
                    month: (this.selectedMonth + 1).toString(),
                    year: this.selectedYear.toString(),
                })
                .subscribe(items => {
                    if (!items) {
                        this.events = [];
                        return;
                    }
                    this.events = items.map(item => {
                        return {
                            start: new Date(item.startDate),
                            title: item.title,
                            id: item.id as string,
                            color: {
                                primary: this.getRandomColor(),
                                secondary: this.getRandomColor(),
                            },
                        };
                    });
                });
        }
    }

    public getDetailEvent(eventId: string): Observable<Event> {
        return this.eventService.getPublishedEvent(eventId);
    }

    public showMoreEvents(events: CalendarEvent[]): void {
        this.moreEvents = events.slice(this.visibleEvents); // Get the first 2 events
        this.showMoreDialogOpen = true;
    }

    public closeMoreEventsDialog(): void {
        this.showMoreDialogOpen = false;
        this.moreEvents = [];
    }

    public getFormattedEventDate(): string {
        if (this.moreEvents && this.moreEvents.length > 0) {
            return formatDate(this.moreEvents[0].start, 'd MMM yyyy', 'en-US');
        }
        return ''; // Default value or an alternative message if no date is available
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public ngOnInit(): void {
        this.selectedMonth = new Date().getMonth();
        this.selectedYear = new Date().getFullYear();
        this.updateCalendar();
    }

    // Panggil updateCalendar saat ada perubahan pada month atau year
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedMonth || changes.selectedYear) {
            this.updateCalendar();
        }
    }

    // Utility function to generate an array of years from startYear to endYear
    public generateYears(startYear: number, endYear: number): number[] {
        const years = [];
        for (let year = startYear; year <= endYear; year++) {
            years.push(year);
        }
        return years;
    }
}
