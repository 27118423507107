import { Injectable } from '@angular/core';
import { EducationClient } from '../clients/education.client';
import { EducationStore } from '../stores/education.store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class EducationService {
    public constructor(private client: EducationClient, private store: EducationStore) {}

    public getEducations(): Observable<any> {
        return this.client.getEducations().pipe(
            tap((res: any) => {
                this.store.setEducations(res);
            }),
        );
    }
}
