import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { EducationState } from './states';

@Injectable({
    providedIn: 'root',
})
export class EducationStore extends Store<EducationState> {
    public constructor() {
        super({
            loading: false,
            error: null,
            data: {
                educations: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return { ...state, loading };
        });
    }

    public setErrorMessage(message: string): void {
        this.setState(state => {
            return { ...state, errorMessage: message };
        });
    }

    public setEducations(educations: Array<any>): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, educations } };
        });
    }
}
