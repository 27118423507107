import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { CompanyState } from '../stores/states';
import { CompanyStore } from '../stores/company.store';
import { Observable } from 'rxjs';
import { Company, CompanyPositions } from '@ebursa/company/models/company';
import { Collection } from '@shared/types/collection';
import { CompanyEvent } from '../models/company-event';

@Injectable({ providedIn: 'root' })
export class CompanyRepository extends Repository<CompanyState> {
    public constructor(store: CompanyStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public isLoadingSearchCompanies$(): Observable<boolean> {
        return this.select(state => state.loadingSearchCompanies);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isCodeLoading$(): Observable<boolean> {
        return this.select((state: CompanyState) => state.ui.codeLoading);
    }

    public selectCompanyCode$(): Observable<any> {
        return this.select((state: CompanyState) => state.data.companyCode);
    }

    public selectCompanies$(): Observable<Collection<Company>> {
        return this.select((state: CompanyState) => state.data.companies);
    }

    public selectCompanyModal$(): Observable<Company> {
        return this.select(state => state.ui.companyModal);
    }

    public selectCompany$(): Observable<Company> {
        return this.select((state: CompanyState) => state.data.company);
    }

    public selectAuthCompany$(): Observable<Company> {
        return this.select((state: CompanyState) => state.data.authCompany);
    }

    public selectCompanyEvents$(): Observable<Collection<CompanyEvent>> {
        return this.select(state => state.data.companyEvents);
    }

    public selectListCompanies$(): Observable<Collection<Company>> {
        return this.select(state => state.data.listCompanies);
    }

    public selectCompanyPositions$(): Observable<Collection<CompanyPositions>> {
        return this.select(state => state.data.companyPositions);
    }
}
