import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UbudLoaderModule } from '@ubud/loader';
import { UbudFormModule } from '@ubud/form';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ImageAsyncModule } from '@shared/modules/image-async/image-async.module';
import { CardModule } from '@shared/modules/card/card.module';
import { ComboBoxModule, MultiSelectModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { BodyModule, GridModule, SharedModule } from '@progress/kendo-angular-grid';
import { QuillEditorModule } from '@shared/modules/quill-editor/quill-editor.module';
import { EbursaUserPipeModule } from '@ebursa/web/src/modules/user/pipes/module';
import { NgModule } from '@angular/core';
import { ConfirmationModule } from '@shared/modules/confirmation/confirmation.module';
import { COMPANY_COMPONENTS } from '@ebursa/web/src/modules/company/components/index';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { EbursaCommonPipeModule } from '../../common/pipes/module';
import { SharedCommonModule } from '@shared/modules/common/common.module';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { EbursaCityInputModule } from '@ebursa/web/src/modules/common/inputs/city-input/city-input.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { EbursaTeamRoleInputModule } from '@ebursa/web/src/modules/common/inputs/team-role-input/team-role-input.module';
import { EbursaCommonComponentModule } from '@ebursa/web/src/modules/common/components/module';
import { SearchInputModule } from '@shared/modules/search-input/search-input.module';
import { FilterModule } from '@shared/modules/filter/filter.module';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    23/01/2020
 */
const MODULES: any[] = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UbudLoaderModule,
    UbudFormModule,
    DateInputsModule,
    DialogModule,
    DropDownListModule,
];

const SHARED_MODULES: any[] = [
    ImageAsyncModule,
    CardModule,
    ComboBoxModule,
    GridModule,
    SharedModule,
    MultiSelectModule,
    QuillEditorModule,
    ConfirmationModule,
    SharedCommonModule,
];

const EBURSA_MODULES: any[] = [EbursaUserPipeModule, EbursaCommonPipeModule, EbursaCommonComponentModule];

@NgModule({
    imports: [
        ...MODULES,
        ...SHARED_MODULES,
        ...EBURSA_MODULES,
        BodyModule,
        EbursaCityInputModule,
        AgmCoreModule,
        GooglePlaceModule,
        EbursaTeamRoleInputModule,
        SearchInputModule,
        FilterModule,
    ],
    declarations: [...COMPANY_COMPONENTS],
    exports: [...COMPANY_COMPONENTS],
    providers: [GoogleMapsAPIWrapper],
})
export class EbursaCompanyComponentModule {}
