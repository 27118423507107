import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { Applicant } from '@ebursa/applicant/models/applicant';
import { EventStatus } from '@ebursa/event/enums/event-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    12/02/2020
 */
@Component({
    selector: 'ebursa-applicant-vacancy-dialog-component',
    templateUrl: './applicant-vacancy-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./applicant-vacancy-dialog.component.scss'],
})
export class ApplicantVacancyDialogComponent {
    @Input() public applicantVacancy: ApplicantVacancy;

    @Output() public downloadDocumentClicked: EventEmitter<string> = new EventEmitter<string>();
    @Output() public downloadCVClicked: EventEmitter<string> = new EventEmitter<string>();

    public monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];

    public get applicant(): Applicant {
        return this.applicantVacancy.applicantEvent.applicant;
    }

    public Status = EventStatus;
    public get isShowAlert() {
        return [this.Status.PUBLISHED, this.Status.PENDING].some(item => this.applicantVacancy.applicantEvent.event.status === item);
    }
    public get isShowMessageBtn() {
        return [this.Status.PUBLISHED, this.Status.PENDING, this.Status.GOING].some(
            item => this.applicantVacancy.applicantEvent.event.status === item,
        );
    }
}
