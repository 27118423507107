import { Injectable } from '@angular/core';
import { EducationStore } from '../stores/education.store';
import { Repository } from '@ubud/sate';
import { EducationState } from '../stores/states';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EducationRepository extends Repository<EducationState> {
    public constructor(store: EducationStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getEductions$(): Observable<any> {
        return this.select(state => state.data.educations);
    }
}
