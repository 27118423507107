import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ebursa-download-qr-code-dialog-component',
    templateUrl: './download-qr-code-dialog.component.html',
    styleUrls: ['./download-qr-code-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadQrCodeDialogComponent {
    @Input() public isOpen: boolean = false;
    @Input() public confirmMessage: string = 'Apakah Anda yakin?';

    @Output() public confirm: EventEmitter<void> = new EventEmitter<void>();
    @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();

    public closeDialog(): void {
        this.cancel.emit();
    }

    public confirmAction(): void {
        this.confirm.emit();
    }
}
