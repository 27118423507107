import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { EventService } from '@ebursa/event/services/event.service';
import { Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { Collection } from '@shared/types/collection';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { NacoService } from '@naker/naco';
import { Storage } from '@ubud/storage';
import { QueryablePage } from '../../../common/pages/queryable.page';
import { mergeMap } from 'rxjs/operators';
import { Subscriber } from '@ubud/sate';

/**
 * Component for displaying event details in a dialog.
 * Manages the view for event details and related company information.
 * @author Bagas Prasetyadi
 */

interface QueryParams {
    companyPage: number;
    companyLimit: number;
}

class InitialQueryParams implements QueryParams {
    public companyLimit = 5;
    public companyPage = 1;
}

@Component({
    selector: 'ebursa-detail-event-dialog-component',
    templateUrl: './detail-event-dialog.component.html',
    styleUrls: ['./detail-event-dialog.component.scss'],
    animations: [
        trigger('toggleView', [
            state(
                'eventDetailView',
                style({
                    opacity: 1,
                    transform: 'translateY(0)',
                }),
            ),
            state(
                'companyInfoView',
                style({
                    opacity: 1,
                    transform: 'translateY(0)',
                }),
            ),
            transition('eventDetailView => companyInfoView', [
                style({ opacity: 0, transform: 'translateY(-100%)' }),
                animate('0.5s ease-in', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
        ]),
    ],
})
export class DetailEventDialogComponent extends QueryablePage<QueryParams> implements OnChanges {
    @Input() public isDialogOpen: boolean;
    @Input() public eventId: string;
    @Input() public closeDialog: () => void;

    @HostListener('scroll', ['$event'])
    public isScrolled = false;
    public currentView: string = 'eventDetailView';

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private eventRepository: EventRepository,
        private eventService: EventService,
        private companyService: CompanyEventService,
        private companyRepository: CompanyEventRepository,
        private storage: Storage,
        private naco: NacoService,
        private subscriber: Subscriber,
    ) {
        super(router, route, new InitialQueryParams());
    }

    public toggleView() {
        this.currentView = this.currentView === 'eventDetailView' ? 'companyInfoView' : 'eventDetailView';
    }

    public onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > 200) {
            this.currentView = 'companyInfoView';
            this.isScrolled = true;
        } else {
            this.isScrolled = false;
        }
    }

    public login(): void {
        const urlRedirect = '/web/events/' + this.eventId + '/detail';
        this.storage.set('naco_intended_url', this.naco.getCurrentOrigin(urlRedirect)).then(() => {
            this.naco.redirect(this.naco.login('profile email basic', this.naco.getCurrentOrigin('/auth')));
        });
    }

    public getDetailEvent(eventId: string): Observable<Event> {
        console.log({ eventId });
        return this.eventService.getPublishedEvent(eventId);
    }

    public getEventSummaries(eventId: string): Observable<any> {
        return this.eventService.getEventSummaries(eventId);
    }

    private fetchVerifiedCompanies(eventId: string, queries: QueryParams): Observable<any> {
        return this.companyService.getVerifiedCompanyEventsByEvent(eventId, {
            ...queries,
            page: queries['companyPage'] ? queries['companyPage'] : 1,
            limit: queries['companyLimit'] || 5,
        });
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get eventSummaries$(): Observable<any> {
        return this.eventRepository.getEventSummaries$();
    }

    public get loadingEvent$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public get companyEvents$(): Observable<Collection<any>> {
        return this.companyRepository.getCompanyEvents$();
    }

    // Function to reset the view to 'eventDetailView'
    public resetView(): void {
        this.currentView = 'eventDetailView';
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // Check if dialog is being closed
        if (changes.isDialogOpen && !this.isDialogOpen) {
            this.resetView(); // Reset view to 'eventDetailView' when dialog closes
        }

        if (changes.isDialogOpen && this.isDialogOpen && this.eventId) {
            this.getDetailEvent(this.eventId).subscribe();

            this.getEventSummaries(this.eventId).subscribe();

            this.subscriber.subscribe(
                this,
                this.queries$.pipe(mergeMap((queries: QueryParams) => this.fetchVerifiedCompanies(this.eventId, queries))),
            );
        }
    }
}
