import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { EventService } from '@ebursa/event/services/event.service';
import { environment } from '@ebursa/web/src/environments/environment';
import { Signature } from '@naker/naco';
import { Subscriber } from '@ubud/sate';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
    selector: 'ebursa-barcode-checkin-offline-dialog-component',
    templateUrl: './barcode-checkin-offline-dialog.component.html',
    styleUrls: ['./barcode-checkin-offline-dialog-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeCheckinOfflineDialogComponent implements OnInit {
    @Input() public isOpen: boolean = false;
    @Input() public eventId: string;
    @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();
    public barcodeCheckinOfflineUrl$: Observable<string>;

    public constructor(private eventService: EventService, private subscriber: Subscriber, private authRepository: AuthRepository) {}

    public closeBarcodeDialog(): void {
        this.cancel.emit();
    }

    public handleDownloadBarcode(): void {
        this.subscriber.subscribe(
            this,
            this.authRepository.getSignature$().pipe(
                filter(res => !!res),
                tap((signature: Signature) => {
                    window.open(`${environment.endpoint}events/${this.eventId}/qr-code/download?_token=${signature.token}`);
                }),
            ),
        );
    }

    ngOnInit(): void {
        // Assign the observable when the component initializes
        this.barcodeCheckinOfflineUrl$ = this.eventService.getBarcodeOffline(this.eventId);
    }
}
